.not-found {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--text);
}

.sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  margin: -1px;
}

.not-found .center-container {
  max-width: 45rem;
  text-align: center;
  width: 100%;
  padding: 0 2rem;
  margin-top: 5rem;
}

.not-found .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem 2rem;
  width: 100vw;
  box-shadow: 0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
}

.not-found .header-logo {
  background: url('/public/logo.png') no-repeat center left;
  background-size: contain;
  padding-top: 0.5em;
  width: 16rem;
  height: 3rem;
}

.not-found .title {
  background: url('/public/sun-404.png') no-repeat top center;
  padding-top: 7rem;
}

.not-found .back-on-track {
  margin-top: 3rem;
  border-radius: 0.5rem;
  background: var(--color-grey-100, #f9fafb);
  padding: 1.5rem;
  text-align: left;
}

.not-found .back-on-track > p {
  padding: 1em 0;
  font-family: Public Sans, sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem; /* 157.143% */
}

.not-found .back-on-track > p > a {
  text-decoration: underline;
  color: #36f;
  padding-left: 6px;
}

.not-found .phone-contact {
  padding: 0;
}
