.Walkthrough .loading-skeleton {
  background: url('/public/Content.jpg') no-repeat top center;
  margin-top: 3rem;
  width: 100vh;
  height: 100vh;
}

.Walkthrough {
  display: flex;
  flex-direction: column;
  font-family: Public Sans, sans-serif;
  align-items: center;
  justify-content: center;
  color: var(--text);
}

.Walkthrough .sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  margin: -1px;
}

.Walkthrough .center-container {
  margin-top: 1rem;
  max-width: 49rem;
  padding: 0 1rem;
}

.Walkthrough .btn {
  background-color: #f99c2e;
  color: white;
  font-family: Public Sans, sans-serif;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.625rem; /* 173.333% */
}

.Walkthrough .btn:hover {
  background-color: rgba(215, 119, 5, 1);
}

.Walkthrough .loading-text {
  font-size: calc(10px + 2vmin);
}

.Walkthrough .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem;
  width: 100vw;
  box-shadow: 0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
}

.Walkthrough .header-logo {
  background: url('/public/logo.png') no-repeat center left;
  background-size: contain;
  padding: 0.5em;
  margin-right: 1rem;
  width: 12rem;
  height: 3rem;
}

.Walkthrough .intro {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem 0;
}

.Walkthrough .intro > p {
  padding: 0 0 1rem 0;
}

.Walkthrough .intro-title {
  font-size: 1.125rem;
  margin: 1rem 0;
}

.Walkthrough .quote-author::before {
  background-image: url('/public/shawn.jpeg');
  background-size: cover;
  border-radius: 50%;
  display: inline-block;
  height: 3em;
  width: 3em;
  content: '\00a0';
  margin-right: 1rem;
}

.Walkthrough .quote-separator {
  margin-bottom: 1rem;
}

.Walkthrough .quote-author {
  line-height: 3em;
}

.Walkthrough .section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
}

.Walkthrough .footnote {
  font-size: 0.8rem;
  font-style: italic;
}

.Walkthrough .section-title {
  font-size: 2rem;
  margin-top: 1rem;
}

.Walkthrough .section-item {
  display: block;
  margin-top: 1.3rem;
}

.Walkthrough .video-container {
  margin: auto;
  width: 100%;
  padding: 0;
}

.Walkthrough .video {
  border-radius: 0.5rem;
  height: 100%;
  width: 100%;
  object-fit: contain;
  border: 1px solid rgba(196, 205, 213, 1);
}

.Walkthrough .cta {
  margin: 0;
  padding: 1.5rem;
  border-radius: 0.5rem;
  border: 2px solid #f9c02e;
  background: linear-gradient(180deg, #fff 0%, #fff6ec 100%);
  box-shadow: 0px 24px 48px 0px rgba(249, 156, 46, 0.24);
}

.Walkthrough .btn {
  width: 100%;
}

.Walkthrough .cta > p {
  color: rgba(69, 79, 91, 1);
  background: url('/public/info.png') no-repeat no-repeat;
  background-position: 0 1.2rem;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  padding: 1rem 0 0 1.5rem;
}

.Walkthrough .contact {
  padding: 1rem;
  font-family: Public Sans, sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem; /* 157.143% */
  display: flex;
  flex-direction: column;
}

.Walkthrough .contact > div {
  padding-right: 4rem;
}

.Walkthrough .contact-detail {
  background: url('/public/logo.svg') no-repeat top left;
  padding-top: 2.5em;
  margin: 2rem 0 1rem;
}

.Walkthrough .contact-detail > p {
  padding: 0;
  margin: 0 0 0.5rem;
}

.Walkthrough .created-for-title,
.Walkthrough .customer-rep-title {
  font-weight: 600;
}

.Walkthrough .created-for-border,
.Walkthrough .customer-rep-border {
  border-top: 1px solid rgba(145, 158, 171, 0.24);
  padding-top: 1rem;
}

.Walkthrough .created-for-border > p,
.Walkthrough .customer-rep-border > p {
  padding: 0;
  margin: 0 0 0.5rem;
}

@media only screen and (min-width: 601px) {
  .Walkthrough .header {
    padding: 1rem 2rem;
  }

  .Walkthrough .header-logo {
    background: url('/public/logo.svg') no-repeat center left;
    background-size: contain;
    padding: 0.5em;
    margin-right: 1rem;
    width: 16rem;
    height: 3rem;
  }

  .Walkthrough .intro .intro-title {
    font-family: Public Sans, sans-serif;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 3rem; /* 150% */
  }

  .Walkthrough .intro .intro-title::after {
    content: '\1F44B';
    padding-left: 0.4em;
  }

  .Walkthrough .contact {
    flex-direction: row;
    padding-top: 2.5rem;
  }

  .Walkthrough .created-for,
  .Walkthrough .customer-rep {
    padding-top: 1.3rem;
  }

  .Walkthrough .created-for-border,
  .Walkthrough .customer-rep-border {
    min-height: 6rem;
    padding-left: 2rem;
    border-top: none;
    border-left: 1px solid rgba(145, 158, 171, 0.24);
  }
}
