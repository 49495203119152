/* borrowed directly from customer portal frontend */
:root {
  --drawer-width: 280px;

  --max-width: 720px;

  --spacing-base: 8px;
  --spacing-regular: 16px;
  --spacing-medium: 24px;
  --spacing-large: 48px;
  --spacing--xlarge: 96px;

  --border-radius: 4px;

  /* colors */

  --grey-50: rgba(249, 250, 251, 1);
  --grey-100: rgba(242, 244, 247, 1);
  --grey-300: rgba(208, 213, 221, 1);
  --grey-400: rgba(196, 205, 213, 1);
  --grey-500: rgba(145, 158, 171, 1);
  --grey-700: rgba(69, 79, 91, 1);
  --grey-800: rgba(33, 43, 54, 1);
  --grey-900: rgba(16, 24, 40, 1);

  --blue: rgba(0, 99, 234, 1);

  --yellow: rgba(249, 156, 46, 1);
  --orange: rgba(236, 160, 74, 1);
  --yellow-dark: rgba(215, 119, 5, 1);

  --cta: var(--orange);
  --primary: var(--yellow);
  --text: var(--grey-900);
  --header-bg: #f7f7f7;
  --footer-bg: #f7f7f7;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  font-weight: 400;
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  color: var(--text);
  background: #fff;
  line-height: 1.5;
  font-family: sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1;
  font-weight: 700;
}

/* my custom overrides */
p {
  padding: 1rem;
}

/* Button Styles */
.btn {
  padding: 10px 20px; /* Good padding around the button */
  background-color: #fff; /* Background color */
  color: var(--yellow-dark); /* Font color */
  border: none; /* No border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer looks like a finger when hovering over the button */
  transition: background-color 0.3s, transform 0.1s; /* Smooth transition for hover and click effects */
  outline: none; /* Remove default focus outline */
  font-size: 24px; /* Font size */
}

/* Hover effect */
.btn:hover {
  background-color: #ececec; /* Darken the background color a bit on hover */
}

/* Active (click) effect */
.btn:active {
  transform: scale(
    0.95
  ); /* Make the button look like it's being clicked by slightly reducing its size */
}

/* Optional: If you want to add a focus style for accessibility */
.btn:focus {
  box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.5); /* Add a light blue focus ring around the button */
}
